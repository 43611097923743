import { useEffect, useState } from "react";
import { Event } from "../reclaim-api/Events";
import { OneOnOne, RecurringAssignmentInstance } from "../reclaim-api/OneOnOnes";
import { SchedulingLink } from "../reclaim-api/scheduling-links/SchedulingLinks";
import { Task, TaskInstance } from "../reclaim-api/Tasks";
import { KeysByValue } from "../types";

/**
 * Sort direction
 */
export type SortStateDirection = "asc" | "desc";

/**
 * The state of sorting
 */
export interface SortState<S extends string, T> {
  sortOrder: S[];
  items: T[];
  direction?: SortStateDirection;
}

/**
 * Update options for the updateSortState function
 */
export interface SortStateUpdateOptions<S extends string> {
  sortBy?: S;
  direction?: SortStateDirection;
}

/**
 * A comparitor to feed into a sort function
 */
export type SortStateComparitor<T> = (a: T, b: T) => number;

/**
 * A mapping of comparitors to keys
 */
export type SortStateComparitors<S extends string, T> = Record<S, SortStateComparitor<T>>;

export type SortStateMultiComparitor<T> = [T, T, SortStateComparitor<T>];

/**
 * Copies a SortState object, making new references for the object itself, as well as sortOrder and items.  Will NOT make copies of objects in items.
 * @param state The state to copy
 * @returns A new state object
 */
export const copySortState = <S extends string, T>({ sortOrder, items }: SortState<S, T>): SortState<S, T> => ({
  sortOrder: [...sortOrder],
  items: [...items],
});

/**
 * Returns a new SortState with items ordered consistantly with the internal values of sortOrder and direction, and according to the functions in comparitors
 * @param state The current SortState
 * @param comparitors A map of comparitors for sorting
 * @param update The properties in SortState to update
 * @returns A new SortState with items sorted
 */
export function updateSortState<S extends string, T>(
  state: SortState<S, T>,
  comparitors: SortStateComparitors<S, T>,
  update?: SortStateUpdateOptions<S>
): SortState<S, T> {
  const { sortBy, direction = "asc" } = update || {};

  state = copySortState(state);
  state.direction = direction;
  const revMult = direction === "asc" ? 1 : -1;

  if (typeof sortBy === "string") {
    const index = state.sortOrder.indexOf(sortBy);
    if (index !== -1) {
      state.sortOrder.splice(index, 1);
      state.sortOrder.unshift(sortBy);
    }
  }

  function compare(a: T, b: T, sortByIndex = 0): number {
    const sortBy = state.sortOrder[sortByIndex];
    if (typeof sortBy !== "string") return 0;
    const result = comparitors[sortBy](a, b) * revMult;
    if (!result) return compare(a, b, sortByIndex + 1);
    return result;
  }

  state.items.sort(compare);

  return state;
}

/**
 * Hook for maintaining a SortState in React components with items ordered consistantly with the internal values of sortOrder and direction, and according to the functions in comparitors
 * @param state The current SortState
 * @param comparitors A map of comparitors for sorting
 * @param update The properties in SortState to update
 * @returns A new SortState with items sorted
 */
export function useSortState<S extends string, T>(
  state: SortState<S, T>,
  comparitors: SortStateComparitors<S, T>,
  update?: SortStateUpdateOptions<S>
): SortState<S, T> {
  const [internalState, setInternalState] = useState<SortState<S, T>>(state);

  useEffect(() => {
    setInternalState(updateSortState(state, comparitors, update));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update?.sortBy, update?.direction, state.items, state.direction, state.sortOrder]);

  return internalState;
}
export function useSort<S extends string, T>(
  items: T[],
  initialSortOrder: S[],
  comparitors: SortStateComparitors<S, T>,
  initialDirection: SortStateDirection = "asc"
): [state: SortState<S, T>, setSortBy: (sortBy: S) => void, setDirection: (direction: SortStateDirection) => void] {
  const [sortBy, setSortBy] = useState(initialSortOrder[0]);
  const [direction, setDirection] = useState(initialDirection);

  const sortState = useSortState(
    {
      items,
      sortOrder: initialSortOrder,
    },
    comparitors,
    {
      sortBy,
      direction,
    }
  );

  return [sortState, setSortBy, setDirection];
}

/**
 * Checks if array is sorted according to comparitor.  O(n) compared to JS .sort which is O(n log n).
 * @param arr an array to check
 * @param comparitor the comparitor to use
 */
export function isSorted<T>(arr: T[], comparitor: (a: T, b: T) => number): boolean {
  for (let i = 0; i < arr.length - 1; i++) if (comparitor(arr[i], arr[i + 1]) > 0) return false;
  return true;
}

/**
 * comparitor for number arrays
 */
export const numComparitor: SortStateComparitor<number | undefined> = (a = Infinity, b = Infinity) =>
  a === b ? 0 : a - b;
/**
 * comparitor for string arrays
 */
export const alphaComparitor: SortStateComparitor<string | undefined> = (a = "", b = "") => {
  a = a.toLowerCase();
  b = b.toLowerCase();
  // eslint-disable-next-line no-nested-ternary
  return a === b ? 0 : a > b ? 1 : -1;
};
/**
 * comparitor for boolean arrays
 */
export const boolComparitor: SortStateComparitor<boolean | undefined> = (a = false, b = false) =>
  // eslint-disable-next-line no-nested-ternary
  a === b ? 0 : a && !b ? -1 : 1;
/**
 * comparitor for date arrays
 */
export const dateComparitor: SortStateComparitor<Date | undefined | null> = (a, b) => {
  if (a?.getTime() === b?.getTime()) return 0;
  if (!a) return 1;
  if (!b) return -1;
  return a.getTime() - b.getTime();
};
/**
 * comparitor for Event arrays by start date
 */
export const eventStartDateComparitor: SortStateComparitor<Event | undefined> = (a, b) =>
  dateComparitor(a?.eventStart, b?.eventStart);

export function makeComparitorFromList<T>(list: T[]): SortStateComparitor<T> {
  const map = list.reduce((acc, item, i) => {
    acc.set(item, i);
    return acc;
  }, new Map<T, number>());

  return (a, b) => numComparitor(map.get(a), map.get(b));
}

export function multiComparitor<A>(comp: SortStateMultiComparitor<A>): number;
export function multiComparitor<A, B>(compA: SortStateMultiComparitor<A>, compB: SortStateMultiComparitor<B>): number;
export function multiComparitor<A, B, C>(
  compA: SortStateMultiComparitor<A>,
  compB: SortStateMultiComparitor<B>,
  compC: SortStateMultiComparitor<C>
): number;
export function multiComparitor<A, B, C, D>(
  compA: SortStateMultiComparitor<A>,
  compB: SortStateMultiComparitor<B>,
  compC: SortStateMultiComparitor<C>,
  compD: SortStateMultiComparitor<D>
): number;
export function multiComparitor<A, B, C, D, E>(
  compA: SortStateMultiComparitor<A>,
  compB: SortStateMultiComparitor<B>,
  compC: SortStateMultiComparitor<C>,
  compD: SortStateMultiComparitor<D>,
  compE: SortStateMultiComparitor<E>
): number;
export function multiComparitor(...comps: SortStateMultiComparitor<unknown>[]): number;
export function multiComparitor(...comps: SortStateMultiComparitor<unknown>[]): number {
  if (!comps[0]) return 0;

  const [a, b, comparitor] = comps[0];

  const result = comparitor(a, b);
  if (!result) {
    const [, ...newComp] = comps;
    return multiComparitor(...newComp);
  }

  return result;
}

/**
 * Creates a comparitor for sorting objects
 * @param key the key to sort on
 * @param comparitor the comparitor
 * @returns
 */
export const sortOnPropComparitor =
  <T, D>(key: KeysByValue<T, D>, comparitor: SortStateComparitor<D>): SortStateComparitor<T> =>
  (a, b) =>
    comparitor(a[key as unknown as keyof T] as unknown as D, b[key as unknown as keyof T] as unknown as D);

/**
 * Creates a comparitor for sorting objects by numeric keys
 * @param key the key to sort on
 * @returns
 */
export const sortOnNumPropComparitor = <T>(key: KeysByValue<T, number>): SortStateComparitor<T> =>
  sortOnPropComparitor(key, numComparitor);

/**
 * Creates a comparitor for sorting objects by Date keys
 * @param key the key to sort on
 * @returns
 */
export const sortOnDatePropComparitor = <T>(key: KeysByValue<T, Date | undefined | null>): SortStateComparitor<T> =>
  sortOnPropComparitor(key, dateComparitor);

export function instanceStartTimeComparitor(
  a: TaskInstance | RecurringAssignmentInstance,
  b: TaskInstance | RecurringAssignmentInstance
) {
  if (a.start?.getTime() === b.start?.getTime()) return 0;
  if (!a.start) return 1;
  if (!b.start) return -1;
  return a.start.getTime() - b.start.getTime();
}

export const taskFinishedComparitor = (a: Task, b: Task) => {
  if (a.finished?.getTime() === b.finished?.getTime()) return 0;
  if (!a.finished) return -1;
  if (!b.finished) return 1;
  return b.finished.getTime() - a.finished.getTime();
};

export const taskIndexComparitor = (a: Task, b: Task) =>
  (!!a.index || a.index === 0 ? a.index : Infinity) - (!!b.index || b.index === 0 ? b.index : Infinity);

export const oneOnOneTitleComparitor = (a: OneOnOne, b: OneOnOne) => (a.title || "").localeCompare(b.title || "");

export const schedulingLinksTitleAndEnabledComparitor = (a: SchedulingLink, b: SchedulingLink) =>
  multiComparitor([a.enabled, b.enabled, boolComparitor], [a.title, b.title, alphaComparitor]);
